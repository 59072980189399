import React from "react";
import CustomInput from "../CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Check from "@material-ui/icons/Check";
import {green} from "@material-ui/core/colors";
import Lock from "@material-ui/icons/Lock";

import styles from "assets/jss/material-kit-react/views/loginPage.js";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function Password(props) {
    const classes = useStyles();
    const [okFields, setOkFields] = React.useState([])
    const requiredFields = ['password', 'password2']
    const [passwordActive, setPasswordActive] = React.useState(false)
    const [passComplyRules, setPassComplyRules] = React.useState([])
    const [password, setPassword] = React.useState(null)
    const [password2, setPassword2] = React.useState("122")
    const specialChars = '!,$,%,&,(,),%,@'
    const specialCharArr = specialChars.split(',')
    const labelText = props.labelText ? props.labelText : 'Password'
    const passLengthRule = {
        id: 0,
        description: 'minimum length 8 characters',
        required: true,
        checker: v => v && v.length >= 8
    }
    const passRulesComplyLength = 3
    const passRulesAvailable = [
        {id: 1, description: 'At least one upper case letter', checker: v => v && /[A-Z]/.test(v)},
        {id: 2, description: 'At least one lower case letter', checker: v => v && /[a-z]/.test(v)},
        {id: 3, description: 'At least one number', checker: v => v && /[0-9]/.test(v)},
        {
            id: 4, description: `At least one of the following characters: ${specialChars}`,
            checker: v => specialCharArr.filter(s => v.indexOf(s) >= 0).length > 0
        }
    ]
    const fieldOk = name => okFields.indexOf(name) >= 0

    const onPasswordChange = (value) => {
        const tmp = passRulesAvailable.filter(r => r.checker(value)).map(r => r.id)
        setPassComplyRules(tmp)
        setPassword2("")
        if (tmp.length >= passRulesComplyLength && passLengthRule.checker(value)) {
            if (okFields.indexOf('password') < 0)
                setOkFields(['password'])
            setPasswordActive(false)
            sendOnChangeEvent(['password'])
        } else {
            setPasswordActive(true)
            setOkFields([])
            sendOnChangeEvent([])
        }
        setPassword(value)
    }

    const onPassword2Change = (value) => {
        setPassword2(value)
        if (password == value) {
            if (okFields.indexOf('password2') < 0)
                setOkFields(['password2', ...okFields])
            sendOnChangeEvent(['password2', ...okFields])
        } else {
            setOkFields(okFields.filter(f => f != 'password2'))
            sendOnChangeEvent(okFields.filter(f => f != 'password2'))
        }
    }

    const sendOnChangeEvent = (okFields) => {
        if (props.onChange && okFields.length == 2)
            props.onChange({target: {value: password}})
        else if (props.onChange)
            props.onChange({target: {value: null}})
    }

    return <div>
        <CustomInput
            labelText={`${labelText} *`}
            id="password"
            formControlProps={{
                fullWidth: true
            }}
            inputProps={{
                type: 'password',
                required: true,
                autoComplete: "new-password",
                onFocus: e => setPasswordActive(true),
                onBlur: e => setPasswordActive(false),
                onChange: e => onPasswordChange(e.target.value),
                endAdornment: (
                    <InputAdornment position="end">
                        {fieldOk('password') &&
                        <Check style={{color: green[500]}}/>}
                        {!fieldOk('password') &&
                        <Lock className={classes.inputIconsColor}/>}
                    </InputAdornment>
                )
            }}/>
        {passwordActive && <div>
            {`Password needs to have ${passLengthRule.description} and meet at list ${passRulesComplyLength} of the following rules:`}
            <ul>
                {passRulesAvailable.map(r =>
                    <li>{r.description} {passComplyRules.indexOf(r.id) >= 0 &&
                    <Check style={{
                        position: 'absolute',
                        right: '20%',
                        color: green[500]
                    }}/>}</li>)}
            </ul>

        </div>}
        <CustomInput
            labelText={`Confirm ${labelText} *`}
            id="password2"
            formControlProps={{
                fullWidth: true
            }}
            inputProps={{
                required: true,
                autoComplete: "new-password",
                type: 'password',
                onChange: e => onPassword2Change(e.target.value),
                endAdornment: (
                    <InputAdornment position="end">
                        {fieldOk('password2') &&
                        <Check style={{color: green[500]}}/>}
                        {!fieldOk('password2') &&
                        <Lock className={classes.inputIconsColor}/>}
                    </InputAdornment>
                )
            }}/>
    </div>

}