import Actions from "./Actions";

export const loginPending = (username, password) => {
    return {type: Actions.LOGIN_PENDING, credentials: {username, password}}
}

export const loginSuccess = (username, authentication) => {
    return {type: Actions.LOGIN_SUCCESS, authentication: {...authentication, username}}
}

export const loginError = error => {
    return {type: Actions.LOGIN_ERROR, error}
}

export const changeUsername = username => {
    return {type: Actions.CHANGE_USERNAME, value: username}
}

export const changePassword = password => {
    return {type: Actions.CHANGE_PASSWORD, value: password}
}


//Forgot

export const forgotPending = (username, password) => {
    return {type: Actions.FORGOT_PENDING, credentials: {username, password}}
}


export default {
    loginPending,
    loginSuccess,
    loginError,
    changeUsername,
    changePassword
}