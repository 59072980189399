import React from "react";
import ReactDOM from "react-dom";
import {Route, Router, Switch} from "react-router-dom";
import {Provider} from 'react-redux'
import history from 'network/History'
import store from 'reducers/Store'

import "assets/scss/material-kit-react.scss?v=1.8.0";
// pages for this product
import LandingPage from "views/LandingPage";
import ProfilePage from "views/ProfilePage";
import LoginPageContainer from "views/LoginPage/Container";
import ForgotPasswordPage from "views/ForgotPasswordPage";
import Account from "./views/api-docs/Account";
import Funds from "./views/api-docs/Funds";
import GettingStartedPage from "./views/GettingStartedPage";
import Consents from "./views/api-docs/Consents";
import FundsConfirmation from "./views/api-docs/Funds-Confirmation";
import Payments from "./views/api-docs/Payments";
import CreateAccountPage from "./views/CreateAccountPage";
import ApplicationsPage from "./views/ApplicationsPage";


ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <Switch>
                <Route path="/getting-started" component={GettingStartedPage}/>
                <Route path="/profile" component={ProfilePage}/>
                <Route path="/login" component={LoginPageContainer}/>
                <Route path="/forgot" component={ForgotPasswordPage}/>
                <Route path="/create-account" component={CreateAccountPage}/>
                <Route path="/applications" component={ApplicationsPage}/>
                <Route path="/api-docs/account" component={Account}/>
                <Route path="/api-docs/funds" component={Funds}/>
                <Route path="/api-docs/funds-confirmation" component={FundsConfirmation}/>
                <Route path="/api-docs/consents" component={Consents}/>
                <Route path="/api-docs/payments" component={Payments}/>
                <Route path="/" component={LandingPage}/>
            </Switch>
        </Router>
    </Provider>,
    document.getElementById("root")
);
