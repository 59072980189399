import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";


import styles from "assets/jss/material-kit-react/views/loginPage.js";

// Sections for this page
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import People from "@material-ui/core/SvgIcon/SvgIcon";
import Icon from "@material-ui/core/Icon/Icon";
import Email from "@material-ui/icons/Email";
import Person from "@material-ui/icons/Person";
import {CircularProgress} from "@material-ui/core";
import {FetchApplications} from "../../network/ApiCalls";

const useStyles = makeStyles(styles);

export default function ApplicationsPage(props) {
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    const [applications, setApplications] = React.useState([])
    React.useEffect()

    setTimeout(function() {
        setCardAnimation("");
    }, 200);
    const classes = useStyles();
    const { ...rest } = props;
    const apps = FetchApplications()
    console.log(apps)
    return (
        <div>

        </div>
    );
}
