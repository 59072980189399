import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import EmailValidator from "email-validator";
import Switch from '@material-ui/core/Switch';
import Link from '@material-ui/core/Link';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';


import styles from "assets/jss/material-kit-react/views/loginPage.js";

import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Check from "@material-ui/icons/Check"
import Person from "@material-ui/icons/Person";
import Business from "@material-ui/icons/Business"
import Email from "@material-ui/icons/Email"
import {CircularProgress, Snackbar} from "@material-ui/core";
import {dangerColor} from "../../assets/jss/material-kit-react";
import {green} from '@material-ui/core/colors';

import Password from 'components/Password'
import OTPInput from "react-otp-input";
import {CreateAccountStep1, CreateAccountStep2, ForgotStep1} from "../../network/ApiCalls";
import history from "../../network/History";
import SnackbarContent from "../../components/Snackbar/SnackbarContent";

// @material-ui/icons

const useStyles = makeStyles(styles);

export default function CreateAccountPage(props) {
    const [cardAnimation, setCardAnimation] = React.useState("cardHidden");
    const requiredFields = ['firstName', 'lastName', 'email', 'organization', 'password', 'terms']
    const [error, setError] = React.useState("")
    const [step, setStep] = React.useState('step1')
    const [pending, setPending] = React.useState(false)
    const [okFields, setOkFields] = React.useState([])
    const [termsOpen, setTermsOpen] = React.useState(false)
    const [formData, setFormData] = React.useState({})
    const [otp, setOtp] = React.useState(null)
    const [otpSent, setOtpSent] = React.useState(false)

    const btDisabled = !(requiredFields.filter(f => okFields.includes(f)).length === requiredFields.length)

    const onEmailChange = email => {
        setFormData({...formData, email: email})
        if (!email) {
            setError(null)
            setOkFields(okFields.filter(v => v != 'email'))
        } else if (EmailValidator.validate(email)) {
            setError(null);
            if (okFields.indexOf('email') < 0)
                setOkFields(['email', ...okFields])
        } else {
            setError('email not valid')
            setOkFields(okFields.filter(v => v != 'email'))
        }
    }

    const onChangeOtp = otp => {
        setOtp(otp)
        setError(null)
        if (otp && otp.length == 4) {
            setPending(true)
            CreateAccountStep2(formData.email, otp).then(response => {
                setPending(false)
                setStep('step3')
            }).catch(e => {
                setPending(false)
                setError(e.response.data.error)
            })
        }
    }

    const onFieldChange = (name, value) => {
        setError(null)
        const newFormData = {...formData}
        newFormData[name] = value
        setFormData(newFormData)
        if (!value)
            setOkFields(okFields.filter(v => v != name))
        else if (value && okFields.indexOf(name) < 0) {
            setOkFields([name, ...okFields])
        }
    }

    const onCreateAccountClicked = e => {
        const f = formData
        setPending(true)
        CreateAccountStep1(f.email, f.password, f.firstName, f.lastName, f.organization).then(response => {
            setPending(false)
            setStep('step2')
        }).catch(e => {
            setPending(false)
            setError(e.response.data.error)
        })
    }

    const resendOtp = e => {
        setPending(true)
        ForgotStep1(formData.email).then(data => {
            setPending(false)
            setOtpSent(true)
            setTimeout(() => setOtpSent(false), 2000)
        }).catch(e => {
            setPending(false)
            setError(e.response.data.error)
        })
    }

    const fieldOk = name => okFields.indexOf(name) >= 0

    setTimeout(function () {
        setCardAnimation("");
    }, 200);
    const classes = useStyles();
    const {...rest} = props;
    return (
        <div>
            <Header
                color="transparent"
                brand={require("assets/img/okto-logo.svg")}
                brand2={require("assets/img/okto-logo-black.svg")}
                brandUrl="/"
                rightLinks={<HeaderLinks {...props} />}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
                {...rest}
            />
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: "url(" + require("assets/img/landing-bg-okto.png") + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                }}
            >
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            {step == 'step1' && <GridItem xs={12} sm={12} md={8}>
                                <Card className={classes[cardAnimation]}>
                                    <CardHeader color="info" className={classes.cardHeader}>
                                        <h4>Create Account</h4>
                                    </CardHeader>
                                    <p className={classes.divider}/>
                                    <CardBody>
                                        <CustomInput
                                            labelText="First Name *"
                                            id="firstname"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: e => onFieldChange('firstName', e.target.value),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {fieldOk('firstName') &&
                                                        <Check style={{color: green[500]}}/>}
                                                        {!fieldOk('firstName') &&
                                                        <Person className={classes.inputIconsColor}/>}
                                                    </InputAdornment>
                                                )
                                            }}/>
                                        <CustomInput
                                            labelText="Last Name *"
                                            id="lastname"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: e => onFieldChange('lastName', e.target.value),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {fieldOk('lastName') &&
                                                        <Check style={{color: green[500]}}/>}
                                                        {!fieldOk('lastName') &&
                                                        <Person className={classes.inputIconsColor}/>}
                                                    </InputAdornment>
                                                )
                                            }}/>
                                        <CustomInput
                                            labelText="Organization *"
                                            id="organization"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                required: true,
                                                onChange: e => onFieldChange('organization', e.target.value),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {fieldOk('organization') &&
                                                        <Check style={{color: green[500]}}/>}
                                                        {!fieldOk('organization') &&
                                                        <Business className={classes.inputIconsColor}/>}
                                                    </InputAdornment>
                                                )
                                            }}/>
                                        <CustomInput
                                            labelText="email *"
                                            id="email"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                required: true,
                                                onChange: e => onEmailChange(e.target.value),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {fieldOk('email') && <Check style={{color: green[500]}}/>}
                                                        {!fieldOk('email') &&
                                                        <Email className={classes.inputIconsColor}/>}
                                                    </InputAdornment>
                                                )
                                            }}/>
                                        <Password onChange={e => onFieldChange('password', e.target.value)}/>
                                        <div><Switch
                                            id="terms"
                                            color="primary"
                                            onChange={e => onFieldChange('terms', e.target.checked)}
                                        /> <Link href="#" onClick={e => setTermsOpen(true)} color="textPrimary">I
                                            have read and agreed with terms of use of OKTO PSD2 Portal</Link></div>
                                        <Dialog onClose={e => setTermsOpen(false)} open={termsOpen}>
                                            <DialogTitle>OKTO PSD2 Portal Terms and Conditions</DialogTitle>
                                            <DialogContentText>
                                                <div style={{
                                                    fontSize: '0.9em',
                                                    padding: '0 30px',
                                                    width: '100%',
                                                    textAlign: 'right'
                                                }}>Last update May 1st 2020
                                                </div>
                                                <div style={{margin: '20px', width: '500px', height: '300px'}}>
                                                    1. You agree that by accessing the Site, you have read,
                                                    understood, and agree to be bound by all of these Terms and
                                                    Conditions.
                                                    If you do not agree with all of these Terms and Conditions,
                                                    then you are expressly prohibited from using the Site and you
                                                    must discontinue use immediately.
                                                    <p/>
                                                    2. Supplemental terms and conditions or documents that may be
                                                    posted on the Site from time to time are hereby
                                                    expressly incorporated herein by reference. We reserve the
                                                    right, in our sole discretion, to make changes or
                                                    modifications to these Terms and Conditions at any time and for
                                                    any reason.
                                                    <p/>
                                                    3. It is your responsibility to periodically review these Terms
                                                    and Conditions to stay informed of updates.
                                                    You will be subject to, and will be deemed to have been made
                                                    aware of and to have accepted, the changes in any revised Terms
                                                    and Conditions by your continued use of the Site after the date
                                                    such revised Terms and Conditions are posted.
                                                    <p/>
                                                    4. The information provided on the Site is not intended for
                                                    distribution to or use by any person or entity in any
                                                    jurisdiction or country where such distribution
                                                    or use would be contrary to law or regulation or which would
                                                    subject us to any registration requirement within such
                                                    jurisdiction or country.
                                                    <p/>
                                                    5. Accordingly, those persons who choose to access the Site from
                                                    other locations do so on their own initiative and are solely
                                                    responsible for compliance with local laws, if and to the extent
                                                    local laws are applicable.
                                                </div>
                                            </DialogContentText>
                                        </Dialog>
                                        <div style={{color: dangerColor}}>&nbsp;{error}</div>
                                    </CardBody>
                                    <CardFooter className={classes.cardFooter}>
                                        <Button disabled={btDisabled || pending} simple color="primary" size="lg"
                                                onClick={onCreateAccountClicked}>{pending &&
                                        <CircularProgress style={{position: 'absolute', left: '5px'}}
                                                          size={18}/>}Create Account</Button>
                                    </CardFooter>
                                </Card>
                            </GridItem>}


                            {step == 'step2' && <GridItem xs={12} sm={12} md={4}>
                                <Card className={classes[cardAnimation]}>
                                    <CardHeader color="info" className={classes.cardHeader}>
                                        <h4>Create Account - Verify your email</h4>
                                    </CardHeader>
                                    <p className={classes.divider}/>
                                    <CardBody>
                                        {otpSent && <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={true}
                                                              message={'A new OTP has been sent to your email'}/>}
                                        An OTP code has been sent to your email. Please type it in:<p/>
                                        <OTPInput
                                            inputStyle={{
                                                width: '3rem',
                                                height: '3rem',
                                                margin: '0 0.7rem',
                                                fontSize: '2rem',
                                                color: 'black',
                                                borderRadius: 4,
                                                border: '1px solid rgba(0,0,0,0.3)',
                                            }}
                                            isDisabled={pending}
                                            autoFocus
                                            OTPLength={4}
                                            onChange={onChangeOtp}
                                            otpType="number"
                                            secure={false}
                                            value={otp}
                                        />
                                        <div style={{color: dangerColor}}>&nbsp;{error}</div>
                                    </CardBody>
                                    <CardFooter className={classes.cardFooter}>
                                        <Button disabled={pending} simple color="primary" size="lg"
                                                onClick={resendOtp}>{pending &&
                                        <CircularProgress style={{position: 'absolute', left: '5px'}}
                                                          size={18}/>}Resend OTP</Button>
                                    </CardFooter>
                                </Card>
                            </GridItem>}

                            {step == 'step3' && <GridItem xs={12} sm={12} md={4}><Card className={classes[cardAnimation]}>
                                <CardHeader color="info" className={classes.cardHeader}>
                                    <h4>Create Account - Success</h4>
                                </CardHeader>
                                <CardBody>
                                    <SnackbarContent
                                        message={"You have successfully created your account."}
                                        color="success"
                                        icon={Check}
                                    />
                                    <div>You can now <Link href="#" onClick={e => history.push(process.env.PUBLIC_URL + '/login')}>Login</Link></div>
                                </CardBody>
                            </Card>
                            </GridItem>}
                        </GridContainer>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
