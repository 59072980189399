/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import {Link} from "react-router-dom";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {withRouter} from "react-router";
import store from "../../reducers/Store";
// @material-ui/icons
import {AccountBox, AddBox, Apps} from "@material-ui/icons";
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import {Snackbar} from "@material-ui/core";
import Actions from "../../reducers/Actions";

const useStyles = makeStyles(styles);

function HeaderLinks(props) {
    const [stateSnackbar, setStateSnackBar] = React.useState(true)
    const classes = useStyles();
    const authenticated = sessionStorage.getItem('accessToken') ? true : false
    const logoutAction = () => store.dispatch({type: Actions.LOGOUT})
    const loggedOut = store.getState().authentication && store.getState().authentication.logoutSuccess && stateSnackbar
    if (loggedOut)
        setTimeout(() => setStateSnackBar(false), 1000)
    return (
        <div>
            {loggedOut && <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={true}
                                    message={'Successfully logged out'}/>}
            <List className={classes.list}>
                <ListItem className={classes.listItem}>
                    <Button
                        onClick={e => props.history.push('/getting-started')}
                        color="transparent"
                        disabled={document.location.href.indexOf('getting-started') > 0}
                        className={classes.navLink}>
                        Getting started
                    </Button>
                </ListItem>
                <ListItem className={classes.listItem}>
                    <CustomDropdown
                        noLiPadding
                        buttonText="API Products"
                        buttonProps={{
                            className: classes.navLink,
                            color: "transparent"
                        }}
                        buttonIcon={Apps}
                        dropdownList={[
                            <Link to="/api-docs/account" className={classes.dropdownLink}>
                                Accounts 1.0
                            </Link>,
                            <Link to="/api-docs/payments" className={classes.dropdownLink}>
                                Payments 1.0
                            </Link>,
                            <Link to="/api-docs/funds" className={classes.dropdownLink}>
                                Funds 1.0
                            </Link>,
                            <Link to="/api-docs/funds-confirmation" className={classes.dropdownLink}>
                                Funds Confirmation 1.0
                            </Link>,
                            <Link to="/api-docs/consents" className={classes.dropdownLink}>
                                Consents 1.0
                            </Link>,
                        ]}
                    />
                </ListItem>
                {!authenticated && <ListItem className={classes.listItem}>
                    <Button
                        onClick={e => props.history.push('/create-account')}
                        color="transparent"
                        className={classes.navLink}>
                        <AddBox className={classes.icons}/> Create Account
                    </Button>
                </ListItem>}
                {!authenticated && <ListItem className={classes.listItem}>
                    <Button
                        onClick={e => props.history.push(process.env.PUBLIC_URL + '/login')}
                        color="transparent"
                        className={classes.navLink}>
                        <AccountBox className={classes.icons}/> Login
                    </Button>
                </ListItem>}
                {authenticated && <ListItem className={classes.listItem}>
                    <CustomDropdown
                        noLiPadding
                        buttonText={store.getState().authentication.username}
                        buttonProps={{
                            className: classes.navLink,
                            color: "transparent"
                        }}
                        buttonIcon={AccountBox}
                        dropdownList={[
                            <Link to="/profile" className={classes.dropdownLink}>
                                Profile
                            </Link>,
                            <Button simple onClick={logoutAction} className={classes.dropdownLink} style={{textTransform: 'none'}} >
                                Logout
                            </Button>
                        ]}
                    />
                </ListItem>}
            </List>
        </div>
    );
}

export default withRouter(HeaderLinks);
