import React from "react";
import {RedocStandalone} from 'redoc';
import {Helmet} from "react-helmet";

// nodejs library that concatenates classes
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// @material-ui/icons

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function ApiDocView(props) {
    const classes = useStyles();
    const {apiYml, apiName, ...rest} = props;
    return (
        <div>
            <Helmet>
                <title> {apiName} | Okto.Pay PSD2 API</title>
            </Helmet>
            <Header
                color="info"
                routes={dashboardRoutes}
                brand={require("assets/img/okto-logo.svg")}
                brand2={require("assets/img/okto-logo-black.svg")}
                brandUrl="/"
                rightLinks={<HeaderLinks {...props} />}
                fixed
                changeColorOnScroll={{
                    height: 0,
                    color: "white"
                }}
                {...rest}
            />
            <div style={{marginTop: 50}}>
            <RedocStandalone spec={require("assets/swagger/" + apiYml)}
                             options={{
                                 scrollYOffset: 80,
                                 nativeScrollbars: false,
                                 theme: {colors: {primary: {main: 'black'}}},
                             }}/>
            </div>
            <div>&nbsp;</div>
            <Footer/>
        </div>
    );
}
