import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";


import styles from "assets/jss/material-kit-react/views/loginPage.js";
// Sections for this page
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Person from "@material-ui/icons/Person";
import {dangerColor} from "../../assets/jss/material-kit-react";
import OTPInput from 'react-otp-input'
import {ForgotStep1, ForgotStep2, ValidateOtp} from "../../network/ApiCalls";
import Icon from "@material-ui/core/Icon";
import {CircularProgress, Snackbar} from "@material-ui/core";
import {Check} from "@material-ui/icons";
import Password from "../../components/Password";

// @material-ui/icons

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
    const [cardAnimation, setCardAnimation] = React.useState("cardHidden");
    const [otp, setOtp] = React.useState("")
    const [btDisabled, setBtDisabled] = React.useState(true)
    const [error, setError] = React.useState("")
    const [pending, setPending] = React.useState(false)
    const [step, setStep] = React.useState('step1')
    const [username, setUsername] = React.useState(null)
    const [password, setPassword] = React.useState(null)
    const [otpSent, setOtpSent] = React.useState(false)
    setTimeout(function () {
        setCardAnimation("");
    }, 200);
    const classes = useStyles();
    const onChangeOtp = otp => {
        setOtp(otp)
        setError(null)
        if (otp && otp.length == 4) {
            setPending(true)
            ValidateOtp(username, otp).then(response => {
                setPending(false)
                setStep('step3')
            }).catch(e => {
                setPending(false)
                setError(e.response.data.error)
            })

        }
    }
    const onButtonClick = e => {
        setPending(true)
        if (step == 'step1')
            ForgotStep1(username).then(data => {
                setPending(false)
                setStep('step2')
            }).catch(e => {
                setPending(false)
                setError(e.response.data.error)
            })
        else if (step == 'step3') {
            ForgotStep2(username, password, otp).then(data => {
                setPending(false)
                setStep('step4')
            }).catch(e => {
                setPending(false)
                setError(e.response.data.error)
            })
        }

    }
    const resendOtp = e => {
        setPending(true)
        ForgotStep1(username).then(data => {
            setPending(false)
            setOtpSent(true)
            setTimeout(() => setOtpSent(false), 2000)
        }).catch(e => {
            setPending(false)
            setError(e.response.data.error)
        })
    }
    return (
        <div>
            <Header
                color="transparent"
                brand={require("assets/img/okto-logo.svg")}
                brand2={require("assets/img/okto-logo-black.svg")}
                brandUrl="/"
                rightLinks={<HeaderLinks {...props} />}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
                {...props}
            />
            {otpSent && <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={true}
                                  message={'A new OTP has been sent to your email'}/>}
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: "url(" + require("assets/img/landing-bg-okto.png") + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                }}
            >
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={4}>
                                <Card className={classes[cardAnimation]}>
                                        <CardHeader color="info" className={classes.cardHeader}>
                                            <h4>Forgot Password</h4>
                                        </CardHeader>
                                        <p className={classes.divider}/>
                                        <CardBody>
                                            {step == 'step1' && <CustomInput
                                                labelText="Email..."
                                                id="email"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                value={username}
                                                inputProps={{
                                                    type: "email",
                                                    onChange: e => {
                                                        setUsername(e.target.value);
                                                        setError(null);
                                                        setBtDisabled(!e.target.value)
                                                    },
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Person className={classes.inputIconsColor}/>
                                                        </InputAdornment>
                                                    )
                                                }}/>}
                                            {step == 'step2' && <div>
                                                <div>Please type OTP code sent to your email</div>
                                                <p/>
                                                {pending &&
                                                <CircularProgress style={{position: 'absolute', left: '150px'}}
                                                                  size={38}/>}
                                                <OTPInput
                                                    inputStyle={{
                                                        width: '3rem',
                                                        height: '3rem',
                                                        margin: '0 0.7rem',
                                                        fontSize: '2rem',
                                                        color: 'black',
                                                        borderRadius: 4,
                                                        border: '1px solid rgba(0,0,0,0.3)',
                                                    }}
                                                    isDisabled={pending}
                                                    autoFocus
                                                    OTPLength={4}
                                                    onChange={onChangeOtp}
                                                    otpType="number"
                                                    secure={false}
                                                    value={otp}
                                                /></div>}
                                            {step == 'step3' && <Password onChange={e => {
                                                setPassword(e.target.value);
                                                setError(null)
                                                setBtDisabled(e.target.value ? false : true)
                                            }}/>}
                                            {step == 'step4' && <SnackbarContent
                                                message={<span><b>SUCCESS:</b> You've changed your password.</span>}
                                                color="success"
                                                icon={Check}
                                            />}
                                            <div style={{color: dangerColor}}>&nbsp;{error}</div>
                                        </CardBody>
                                        <CardFooter className={classes.cardFooter}>
                                            {step == 'step1' &&
                                            <Button disabled={btDisabled} simple color="primary" size="lg"
                                                    onClick={onButtonClick}>{pending &&
                                            <CircularProgress style={{position: 'absolute', left: '5px'}}
                                                              size={18}/>}Proceed</Button>}
                                            {step == 'step2' &&
                                            <Button simple color="primary" size="sm" onClick={resendOtp}>Resend
                                                OTP</Button>}
                                            {step == 'step3' &&
                                            <Button disabled={btDisabled} simple color="primary" size="lg"
                                                    onClick={onButtonClick}>{pending &&
                                            <CircularProgress style={{position: 'absolute', left: '5px'}}
                                                              size={18}/>}Change Password</Button>}
                                        </CardFooter>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
