import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import {Remarkable} from "remarkable"

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/gettingStartedPage.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

const pageMdUrl = require("assets/md/getting-started.md");

export default function GettingStartedPage(props) {
    const classes = useStyles();
    const {...rest} = props;
    const [content, setContent] = React.useState(null);
    if (!content)
        fetch(pageMdUrl).then(r => r.text()).then(content => {
            var md = new Remarkable();
            md.set({
                html: true,
                breaks: true
            });
            setContent(md.render(content));
        });
    return (
        <div>
            <Header
                color="transparent"
                routes={dashboardRoutes}
                brand={require("assets/img/okto-logo.svg")}
                brand2={require("assets/img/okto-logo-black.svg")}
                brandUrl="/"
                rightLinks={<HeaderLinks/>}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax filter image={require("assets/img/get_started.jpg")}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <div style={{marginTop: '200px'}}>&nbsp;</div>
                            <h1 className={classes.title}>Getting started</h1>
                            <h4>
                                Ready to boost your apps? Get started right now with a few simple steps.
                            </h4>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container2}>
                    <div>&nbsp;</div>
                    <div dangerouslySetInnerHTML={{__html: content}} />
                    <div>&nbsp;</div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
