/* eslint-disable */
import Axios from 'axios';

export const Login = async (username, password, refreshToken) => {
    try {
        //form-data Get dynamically username and password , next some options and finally oAuth for access credentials
        let bodyFormData = new FormData();
        if (username || password) {
            bodyFormData.set('username', `${username}`)
            bodyFormData.set('password', `${password}`)
            bodyFormData.set('scope', 'portal')
            bodyFormData.set('grant_type', 'password')
        } else if (refreshToken) {
            bodyFormData.set('refresh_token', `${refreshToken}`)
            bodyFormData.set('scope', 'portal')
            bodyFormData.set('grant_type', 'refresh_token')
        }
        const options = {
            method: 'POST',
            url: '/oauth/token',
            auth: {
                username: 'portal',
                password: '123'
            },
            data: bodyFormData,
            withCredentials: true,
            responseType: 'json',
        };

        const request = Axios(options);

        return await request
    } catch (error) {
        throw error
    }

}

