import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import Face from "@material-ui/icons/Face";
import BusinessIcon from '@material-ui/icons/Business';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/faces/avatar.png";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import {FetchProfile, SubmitApp} from "../../network/ApiCalls";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Check from "@material-ui/icons/Check";
import {green} from "@material-ui/core/colors";
import CustomInput from "../../components/CustomInput/CustomInput";
import {CircularProgress, Snackbar} from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import Switch from "@material-ui/core/Switch";
import Link from "@material-ui/core/Link";
import Password from "../../components/Password";
import {dangerColor} from "../../assets/jss/material-kit-react";
import {dateTimeFormatter} from "../../network/date-utils";
import CustomTabs from "../../components/CustomTabs/CustomTabs";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
    const classes = useStyles();
    const {...rest} = props;
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    const [profileInfo, setProfileInfo] = React.useState({applications: []})

    React.useEffect(() => {
        if (!profileInfo.email)
            FetchProfile().then(response => setProfileInfo(response.data))
    })

    const App = props => {
        const [pending, setPending] = React.useState(false)
        const [subscriptions, setSubscriptions] = React.useState(props.app.subscriptions)
        const [app, setApp] = React.useState(props.app)
        const [error, setError] = React.useState(null)
        const [success, setSuccess] = React.useState(false)
        const onSubscriptionChange = (name, active) => {
            if (active) {
                if (app.subscriptions.filter(s => s.product == name).length == 0)
                    setSubscriptions([...subscriptions, {product: name, type: 'PSD2'}])
            } else
                setSubscriptions(subscriptions.filter(s => s.product != name))
        }
        const onSubmitApp = e => {
            setPending(true)
            SubmitApp({...app, subscriptions}).then(r => {
                setPending(false)
                setProfileInfo({applications: []})
                setSuccess(true)
                setTimeout(() => setSuccess(false), 1500)
            }).catch(e => {
                setError(e.response && e.response.data && e.response.data.error ? e.response.data.error : 'Unable to create app, an error occurred.' )
                setPending(false)
            })
        }
        return <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8} lg={6}>
                {success && <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={true}
                                           message={'App created successfully'}/>}
                {app.created && <CustomInput
                    labelText="Created"
                    id="created"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        disabled: true,
                        value: dateTimeFormatter(new Date(app.created)),
                    }}/>}
                {app.status && <CustomInput
                    labelText="Status"
                    id="status"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        disabled: true,
                        value: app.status,
                    }}/>}
                <CustomInput
                    labelText="Application title *"
                    id="title"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        value: app.title,
                        onChange: e => {
                            setApp({...app, title: e.target.value})
                            setError(null)
                        },
                        endAdornment:
                            app.title && <InputAdornment position="end">
                                <Check style={{color: green[500]}}/>
                            </InputAdornment>
                    }}/>
                <CustomInput
                    labelText="Client Id *"
                    id="clientId"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        value: app.clientId,
                        onChange: e => {
                            setApp({...app, clientId: e.target.value})
                            setError(null)
                        },
                        endAdornment:
                            app.clientId && app.clientId.length >= 8 && app.clientId.indexOf(' ') < 0 && <InputAdornment position="end">
                                <Check style={{color: green[500]}}/>
                            </InputAdornment>
                    }}/>
                <span style={{fontSize: '0.8em'}}>Client Id should have a minimum length of 8 characters and should not contain empty spaces</span>
                {!app.id &&
                <Password labelText="Client Secret" onChange={e => setApp({...app, clientSecret: e.target.value})}/>}
                <div>Select at least one API product:</div>
                <div>
                    <Switch
                        id="accounts"
                        color="primary"
                        checked={subscriptions.filter(s => s.product == 'PSD2_ACCOUNT').length == 1}
                        onChange={e => onSubscriptionChange('PSD2_ACCOUNT', e.target.checked)}
                    /> Account API <div style={{fontSize: '0.8em', marginLeft: '60px'}}>access to accounts (wallets) and
                    transactions
                    <Link target="_blank" href="/api-docs/account"> (API Reference)</Link></div>
                </div>
                <div>
                    <Switch
                        id="funds"
                        color="primary"
                        checked={subscriptions.filter(s => s.product == 'PSD2_FUNDS').length == 1}
                        onChange={e => onSubscriptionChange('PSD2_FUNDS', e.target.checked)}
                    /> Funds API <div style={{fontSize: '0.8em', marginLeft: '60px'}}> deposit/withdraw funds
                    <Link target="_blank" href="/api-docs/funds"> (API Reference)</Link></div>
                </div>
                <div>
                    <Switch
                        id="fundsConfirmation"
                        color="primary"
                        checked={subscriptions.filter(s => s.product == 'PSD2_FUNDS_CONFIRMATION').length == 1}
                        onChange={e => onSubscriptionChange('PSD2_FUNDS_CONFIRMATION', e.target.checked)}
                    /> Funds Confirmation API <div style={{fontSize: '0.8em', marginLeft: '60px'}}> check account
                    balance
                    <Link target="_blank" href="/api-docs/funds-confirmation"> (API Reference)</Link></div>
                </div>
                <div>
                    <Switch
                        id="payments"
                        color="primary"
                        checked={subscriptions.filter(s => s.product == 'PSD2_PAYMENTS').length == 1}
                        onChange={e => onSubscriptionChange('PSD2_PAYMENTS', e.target.checked)}
                    /> Payments API <div style={{fontSize: '0.8em', marginLeft: '60px'}}> perform payments
                    <Link target="_blank" href="/api-docs/payments"> (API Reference)</Link></div>
                </div>
                <div style={{color: dangerColor}}>&nbsp;{error}</div>

                {app.activeSandbox && app.activeSandbox.id && <div>
                    <CustomTabs headerColor="info" title="Sandbox Test Users"
                                tabs={[{tabName: 'Customers',
                                    tabIcon: Face,
                                    tabContent: <div>
                                        A sandbox environment has been created to help you test OKTO API. Sandbox is accessible at <b>https://sandbox.oktopay.eu/api/psd2</b>.
                                        <p/>Sandbox has been provisioned with <b>{app.activeSandbox.customerUsers.length}</b> customers:
                                        <ul>{app.activeSandbox.customerUsers.map(u => <li>Username: {u.username}, Password: {u.password}</li>)}
                                    </ul></div>},
                                    {tabName: 'Merchants',
                                        tabIcon: BusinessIcon,
                                        tabContent: <div>
                                            A sandbox environment has been created to help you test OKTO API. Sandbox is accessible at <b>https://sandbox.oktopay.eu/api/psd2</b>.
                                            <p/>Sandbox has been provisioned with <b>{app.activeSandbox.customerUsers.length}</b> merchants:
                                            <ul>{app.activeSandbox.merchantUsers.map(u => <li>Username: {u.username}, Password: {u.password}</li>)}
                                            </ul></div>}
                                ]} />
                </div>}

                <Button onClick={onSubmitApp} fullWidth={true}
                        disabled={!pending && !(app.title && app.clientId && app.clientId.length >= 8 && (app.id || app.clientSecret) && subscriptions.length > 0)}
                        simple color="primary" size="lg">
                    {pending && <CircularProgress size={18}/>}{app.id ? ' Update' : ' Create'} App</Button>
            </GridItem>
        </GridContainer>
    }

    return (
        <div>
            <Header
                color="transparent"
                brand={require("assets/img/okto-logo.svg")}
                brand2={require("assets/img/okto-logo-black.svg")}
                brandUrl="/"
                rightLinks={<HeaderLinks {...props} />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax filter image={require("assets/img/landing-bg-okto.png")} small={true}>

            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div>
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={6}>
                                <div className={classes.profile}>
                                    <div>
                                        <img src={profile} alt="..." className={imageClasses}/>
                                    </div>
                                    <div className={classes.name}>
                                        <h3 className={classes.title}>{profileInfo.firstName} {profileInfo.lastName}</h3>
                                        <h5>{profileInfo.organization}</h5>
                                        <h6><i className={"fas fa-envelope"}/> {profileInfo.email}</h6>
                                    </div>
                                </div>
                            </GridItem>
                        </GridContainer>
                        <div className={classes.description}>
                            <p>In order to access OKTO PSD2 API, you need to create an application.
                                A PSD2 application offers access to certain products of OKTO PSD2 API and is bound to
                                a client id and secret. <br/>
                                All API Products require the usage of an access token generated using <b>Client
                                    Id</b> and <b>Client Secret</b> via OAuth2 Client Credentials
                                grant by invoking https://sandbox.oktopay.eu/oauth/token.
                            </p>
                            {(!profileInfo.applications || profileInfo.applications.length == 0) &&
                            <p style={{fontSize: '1.3em'}}><InfoIcon style={{position: "relative", top: 10}}
                                                                     fontSize={'large'} color={'action'}/>
                                <b> It appears that you have not created any applications yet. <br/>Let's create a new
                                    right
                                    now.</b>
                            </p>}
                        </div>
                        {profileInfo.applications && profileInfo.applications.length > 0 &&
                        <NavPills
                            alignCenter
                            color="primary"
                            tabs={
                                [
                                    ...profileInfo.applications.map((app, index) => (
                                        {
                                            tabButton: `App ${index + 1}`,
                                            tabIcon: AllInclusiveIcon,
                                            tabContent: <App id={app.id} app={app}/>
                                        }
                                    )),
                                    {
                                        tabButton: "Create App",
                                        tabIcon: AddIcon,
                                        tabContent: <App app={{subscriptions: []}}/>
                                    },
                                ]}
                        />}
                        {profileInfo.applications.length == 0 && <App app={{subscriptions: []}}/>}
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
