import {combineReducers} from 'redux'
import Actions from './Actions'
import history from "../network/History";
import store from "./Store";
import {Logout} from "../network/ApiCalls"


const initialLoginState = {
    username: null,
    password: null,
    pending: false,
    loginSuccess: false
};

function initAuthState() {
    const authData = sessionStorage.getItem("authentication")
    return authData ? JSON.parse(authData) : {}
}

const initialAuthState = initAuthState()

function authentication(state = initialAuthState, action) {
    if (action.type == Actions.LOGIN_SUCCESS) {
        const authState = {
            ...state,
            username: action.authentication.username,
            created: new Date(),
            accessToken: action.authentication.access_token,
            refreshToken: action.authentication.refresh_token,
            accessTokenExpiration: action.authentication.expires_in
        }
        const dateInMillis = new Date().getTime()
        sessionStorage.setItem("authentication", JSON.stringify(authState))
        sessionStorage.setItem("accessToken", action.authentication.access_token)
        sessionStorage.setItem("refreshToken", action.authentication.refresh_token)
        sessionStorage.setItem("accessTokenExpires", dateInMillis + action.authentication.expires_in * 1000)
        return authState
    }
    if (action.type == Actions.LOGOUT) {
        Logout().then(r => {
            sessionStorage.clear()
            history.push('/')
            window.autentication = null
            store.dispatch({type: 'LOGOUT_END'})
        })
        return {logoutSuccess: true}
    }

    if (action.type == 'LOGOUT_END')
        return {logoutSuccess: false}

    return state;
};

function login(state = initialLoginState, action) {
    if (action.type == Actions.LOGIN_PENDING) {
        return {...state, pending: true}
    }
    if (action.type == Actions.LOGIN_SUCCESS) {
        setTimeout(() => history.push('/'), 500)
        return {
            ...state,
            loginSuccess: true,
            pending: false,
        }
    }
    if (action.type == Actions.LOGOUT) {
        return {...initialLoginState}
    }
    if (action.type == Actions.LOGIN_ERROR)
        return {...state, pending: false, error: action.error.error_description}

    if (action.type == Actions.CHANGE_USERNAME)
        return {...state, username: action.value, error: null}
    if (action.type == Actions.CHANGE_PASSWORD)
        return {...state, password: action.value, error: null}
    return state;
}

export default combineReducers({
    login, authentication
});