import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PaymentIcon from '@material-ui/icons/Payment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import DescriptionIcon from '@material-ui/icons/Description';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection(props) {
  const { history } = props;
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Explore our API Products</h2>
          <h5 className={classes.description}>
            Extend your customers’ capabilities via our available PSD2 API Product collection.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Accounts"
              description="Accounts API Product offers access to PSU user account information and transactions."
              icon={AccountBalanceIcon}
              iconColor="info"
              vertical/>
              <Button onClick={e => history.push('/api-docs/account')} color="transparent" style={styles.description}><DescriptionIcon/>Documentation</Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Payments"
              description="Payments API Product allows initiation of wallet payments to other persons or merchants."
              icon={PaymentIcon}
              iconColor="info"
              vertical/>
            <Button onClick={e => history.push('/api-docs/payments')} color="transparent" style={styles.description}><DescriptionIcon/>Documentation</Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Funds"
              description="Funds API Product provides the ability to add (deposit) or remove (withdraw) funds from user account to a connected funding source (external bank account or VISA/Mastercard card)."
              icon={MonetizationOnIcon}
              iconColor="info"
              vertical/>
            <Button onClick={e => history.push('/api-docs/funds')} color="transparent" style={styles.description}><DescriptionIcon/>Documentation</Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
                title="Funds Confirmation"
                description="Funds Confirmation PSD2 API provides TPP the ability to check if PSU user has enough funds on his/her account."
                icon={ConfirmationNumberIcon}
                iconColor="info"
                vertical/>
            <Button onClick={e => history.push('/api-docs/funds-confirmation')} color="transparent" style={styles.description}><DescriptionIcon/>Documentation</Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
                title="Consents"
                description="Consents PSD2 API provides TPP the ability to manage user consents."
                icon={ThumbUpIcon}
                iconColor="info"
                vertical/>
            <Button onClick={e => history.push('/api-docs/consents')} color="transparent" style={styles.description}><DescriptionIcon/>Documentation</Button>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
