import {connect} from 'react-redux'
import LoginPage from '.';
import ActionCreators from "../../reducers/ActionCreators"
import {Login} from "../../network/Login";

const mapStateToProps = state => {
    return {
        username: state.login.username,
        password: state.login.password,
        error: state.login.error,
        pending: state.login.pending,
        loginSuccess: state.login.loginSuccess
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoginClick: (username, password) => {
            dispatch(ActionCreators.loginPending())
            Login(username, password).then(response => {
                dispatch(ActionCreators.loginSuccess(username, response.data))
            }).catch(e => {
                dispatch(ActionCreators.loginError(e.response.data))
            })
        },
        onChangeUsername: e => dispatch(ActionCreators.changeUsername(e.target.value)),
        onChangePassword: e => dispatch(ActionCreators.changePassword(e.target.value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
