import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";


import styles from "assets/jss/material-kit-react/views/loginPage.js";
// Sections for this page
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Icon from "@material-ui/core/Icon/Icon";
import Person from "@material-ui/icons/Person";
import {dangerColor, grayColor} from "../../assets/jss/material-kit-react";
import {CircularProgress, Snackbar} from "@material-ui/core";
import {loginPending} from "../../reducers/ActionCreators";
import {Link} from "react-router-dom";

// @material-ui/icons

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
    const [cardAnimation, setCardAnimation] = React.useState("cardHidden");
    setTimeout(function () {
        setCardAnimation("");
    }, 200);
    const classes = useStyles();
    const {loginSuccess, username, password, pending, onLoginClick, onChangeUsername, onChangePassword, error, ...rest} = props;
    return (
        <div>
            {loginSuccess && <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={true}
                      message={'Successfully logged in'}/>}
            <Header
                color="transparent"
                brand={require("assets/img/okto-logo.svg")}
                brand2={require("assets/img/okto-logo-black.svg")}
                brandUrl="/"
                rightLinks={<HeaderLinks {...props} />}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
                {...rest}
            />
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: "url(" + require("assets/img/landing-bg-okto.png") + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                }}
            >
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={4}>
                                <Card className={classes[cardAnimation]}>
                                    <form className={classes.form}>
                                        <CardHeader color="info" className={classes.cardHeader}>
                                            <h4>Login</h4>
                                        </CardHeader>
                                        <p className={classes.divider}/>
                                        <CardBody>
                                            <CustomInput
                                                labelText="Email..."
                                                id="email"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                value={username}
                                                inputProps={{
                                                    type: "email",
                                                    onChange: onChangeUsername,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Person className={classes.inputIconsColor}/>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                            <CustomInput
                                                labelText="Password"
                                                id="password"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    type: "password",
                                                    onChange: onChangePassword,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Icon className={classes.inputIconsColor}>
                                                                lock_outline
                                                            </Icon>
                                                        </InputAdornment>
                                                    ),
                                                    autoComplete: "off"
                                                }}
                                            />
                                            <div style={{color: dangerColor}}>&nbsp;{error}</div>
                                        </CardBody>
                                        <CardFooter className={classes.cardFooter}>
                                            <Button disabled={pending || !username || !password} simple color="primary" size="lg"
                                                    onClick={e => onLoginClick(username, password)}>{pending &&
                                            <CircularProgress style={{position: 'absolute', left: '5px'}}
                                                              size={18}/>}Login</Button>
                                        </CardFooter>
                                        <CardFooter className={classes.cardFooter}>
                                            <Link to="/forgot">Forgot password</Link>
                                        </CardFooter>
                                    </form>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
