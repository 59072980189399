import Axios from "axios";
import history from "./History";
import {Login} from "./Login"

const axiosOptions = (method, url, params, data, options, contentType) => {
    return {
        method: method,
        url: process.env.PUBLIC_URL + url,
        params: params,
        data: data,
        headers: options.security ? {
            'Accept': 'application/json',
            'Content-Type': contentType || 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
        } : {
            'Accept': 'application/json',
            'Content-Type': contentType || 'application/json',
        },
        timeout: 120000
    }
}

const ServerApi = async (method, url, params, data, options = {}, contentType) => {
    const expires = sessionStorage.getItem("accessTokenExpires")
    const dateInMillis = new Date().getTime();
    if (expires && dateInMillis >= expires) {
        const refreshToken = sessionStorage.getItem("refreshToken")
        const loginResponse = await Login(null, null, refreshToken).catch(error => {
            sessionStorage.clear()
            history.push(process.env.PUBLIC_URL + '/login')
            return
        })
        if (loginResponse.data) {
            sessionStorage.setItem('accessToken', loginResponse.data.access_token)
            sessionStorage.setItem('accessTokenExpires', dateInMillis + loginResponse.data.expires_in * 1000)
            sessionStorage.setItem('refreshToken', loginResponse.data.refresh_token)
        }
    }
    return await Axios(axiosOptions(method, url, params, data, options, contentType)).catch(error => {
        if (error.response.status == 401) {
            sessionStorage.removeItem('accessToken')
            sessionStorage.setItem("accessTokenExpires", dateInMillis)
            return ServerApi(method, url, params, data, options, contentType)
        }
        throw error
    });
}

export const Logout = () => ServerApi('GET', '/sandbox/developer/logout', null, null, {security: true})

export const ValidateOtp = async (email, otp) => {
    let bodyFormData = new FormData();
    bodyFormData.set('otp', otp)
    bodyFormData.set('username', email)
    return ServerApi('POST', '/sandbox/public/otp-valid', null, bodyFormData, {security:false})
}

export const ForgotStep1 = async email => {
    let bodyFormData = new FormData();
    bodyFormData.set('username', email)
    return ServerApi('POST', '/sandbox/public/forgot-step1', null, bodyFormData, {security:false})
}

export const ForgotStep2 = async (email, password, otp) => {
    let bodyFormData = new FormData();
    bodyFormData.set('otp', otp)
    bodyFormData.set('username', email)
    bodyFormData.set('password', password)
    return ServerApi('POST', '/sandbox/public/forgot-step2', null, bodyFormData, {security:false})
}


export const CreateAccountStep1 = async (email, password, firstName, lastName, organization) => {
    let f = new FormData();
    f.set('email', email)
    f.set('password', password)
    f.set('firstName', firstName)
    f.set('lastName', lastName)
    f.set('organization', organization)
    return ServerApi('POST', '/sandbox/public/create-account-step1', null, f, {security:false})
}

export const CreateAccountStep2 = async (email, otp) => {
    let bodyFormData = new FormData();
    bodyFormData.set('otp', otp)
    bodyFormData.set('email', email)
    return ServerApi('POST', '/sandbox/public/create-account-step2', null, bodyFormData, {security:false})
}

export const FetchProfile = async () => {
    return ServerApi('GET', '/sandbox/developer/profile', null, null, {security:true})
}

export const FetchApplications = async () => {
    return await ServerApi('GET', '/sandbox/developer/applications', null, null, {security:true})

}

export const SubmitApp = async app => {
    return await ServerApi('POST', '/sandbox/developer/applications', null, app, {security:true})
}