/* eslint-disable */

import React from 'react';
import format from 'date-fns/format'

export const dateFormatter = (date) => {
    return format(date, 'dd/MM/yyyy');
}

export const dateTimeFormatter = (date) => {
    return format(date, 'dd/MM/yyyy HH:mm');
}


export const isValidIso8601Date = (value) =>
    value ? value.toString().search(/^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/g) === 0 : '';

